<template>
    <div>
        <a-modal :title="getTitle" :width="800" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    保存
                </a-button>
            </template>
            <a-spin :spinning="loading">
                <div class="bg-w pd40">
                    <div>

                      <a-form-model ref="ruleForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
                          <a-form-model-item ref="name" label="社区名称" required prop="name">
                              <a-input v-model="form.name"/>
                          </a-form-model-item>

                        <a-form-model-item required label="位置">
                          <div class="flex alcenter">
                            <a-input :value="form.lat + ',' + form.lng" disabled style="width: 200px; margin-right: 10px; color: #000000;"></a-input>
                            <baidu-map :lat="form.lat" :lng="form.lng" @select="selectAct"></baidu-map>
                          </div>
                        </a-form-model-item>

                      </a-form-model>
                    </div>
                </div>
            </a-spin>
        </a-modal>
    </div>
</template>

<script>
import BaiduMap from '@/components/map/baidumap.vue';
export default {
  components:{
    BaiduMap
  },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        id: {
          type: Number,
          default: 0
        },
        module:{
            type:String,
            default:''
        }
    },
    data() {
        return {
            loading: false,
            confirmLoading: false,
            labelCol: {span: 4},
            wrapperCol: {span: 14},
            rules: {},
            form: {
              id:0,
              name: '',
              lat:'',
              lng:'',
            },
        }
    },
    computed: {
        getTitle() {
          return "添加社区"
        }
    },
    created() {
        this.form.id = this.id;
        this.loaddata();
    },
    methods: {
      loaddata() {
        this.$http.api('platform/authInfo', {
          id: this.form.id,
        }).then(res => {
          if(res.details instanceof Object){
            if(Object.keys(res.details).length !== 0){
              res.details.is_menu = !!res.details.is_menu;
              res.details.is_show = !!res.details.is_show;
              this.form = res.details;
              this.form.sorts = res.details.sort;
            }
          }
          this.pidList = res.adminAuthTree;
          this.loading = false;
        }).catch(res => {
          console.log(res);
          this.loading = false;
        })
      },

      /**
       * 取消弹窗
       */
      handleCancel() {
          this.$emit("cancel");
      },

      /**
       * 提交数据
       */
      onSubmit() {
          this.$refs.ruleForm.validate(valid => {
              if (valid) {
                  if (this.confirmLoading === true) return;
                  this.confirmLoading = true;
                  let p = this.form;
                  this.$http.api('platform/regionSaveCom', p).then(res => {
                      this.$message.success('操作成功', 1, () => {
                          this.confirmLoading = false;
                          this.$emit("ok");
                      })
                  }).catch(res => {
                      console.log(res);
                      this.confirmLoading = false;
                  })
              }
          })
      },
      selectAct(e){
        this.form.lat = e.lat;
        this.form.lng = e.lng;
      },

    }
}
</script>

<style>

</style>
