<template>
  <div>
    <div class="flex space alcenter">
      <div class="ft20 cl-main ftw600">地址库设置</div>
      <a-button v-if="pid != 100000" @click="toback()">返回上级</a-button>
    </div>

    <div class="mt20">
      <div class="bg-w">
        <div class="pd30">

          <div class="wxb-table-gray">
            <a-table rowKey="id" :columns="columns" :pagination="pagination" @expand="tableExpand" :data-source="datas" :loading="loading">

              <div class="flex alcenter center" slot="is_menu" slot-scope="is_menu">
                <a-tag v-if="is_menu == 1" color="green">
                  是
                </a-tag>
                <a-tag v-if="is_menu == 0" color="red">
                  否
                </a-tag>
              </div>
              <div class="flex alcenter center" slot="is_show" slot-scope="is_show">
                <a-tag v-if="is_show == 1" color="green">
                  显示
                </a-tag>
                <a-tag v-if="is_show == 0" color="red">
                  隐藏
                </a-tag>
              </div>
              <template slot="action" slot-scope="record,index">
                <div class="flex center">
                  <a-dropdown placement="bottomRight">
                    <span class="more-act">
                      <i class="iconfont iconmore_gray"></i>
                    </span>
                    <a-menu slot="overlay">
                      <a-menu-item v-if="record.level == 'street'">
                        <a class="menu-act" href="javascript:;" @click="editPartnerAct(record)">
                          <i class="iconfont ft14 iconedit"></i>
                          <span class="ml10">添加社区</span>
                        </a>
                      </a-menu-item>
                      <a-menu-item v-if="record.level == 'street'">
                        <a class="menu-act" href="javascript:;" @click="editPartnerAct(record)">
                          <i class="iconfont ft14 iconedit"></i>
                          <span class="ml10">查看社区</span>
                        </a>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
              </template>
            </a-table>
          </div>
        </div>

      </div>
    </div>

    <div v-if="editPartnerLoading">
      <edit-region :id="id" :visible="editPartnerLoading" @cancel="cancelEditPartner" @ok="okEditPartner"></edit-region>
    </div>

  </div>
</template>

<script>

import {listMixin} from '@/common/mixin/list';
import editRegion from './components/region/modal/editRegion.vue';
export default{
  components:{
    editRegion,
  },
  mixins: [listMixin],
  data(){
    return {
      loading: false,
      editPartnerLoading:false,
      id:0,
      pid:100000,
      dataType:1,//1取下级；2取上级
      pagination: {
        current: 1,
        pageSize: 100, //每页中显示10条数据
        total: 0,
        hideOnSinglePage:true
      },
      columns: [
        {title: 'ID',dataIndex: 'id',align: 'center'},
        {title: '名称',dataIndex: 'name',align: 'center'},
        {title: '编码',dataIndex: 'adcode',align: 'center'},
        {title: '经度',dataIndex: 'lng',align: 'center'},
        {title: '纬度',dataIndex:'lat',align: 'center'},
        {title: '操作',key: 'status',align: 'center',scopedSlots: {customRender: 'action'}},
      ],
      datas: [],

    }
  },
  methods:{
    getLists(){
      if(this.loading===true) return;
      this.loading=true;
      this.$http.api('platform/regionLists',{
        pid:this.pid,
        id:this.id,
        pname:this.pname,
        type:this.dataType,
      }).then(res=>{
        this.pagination.total=res.total;
        this.datas=res.list;
        this.pid=res.pid;
        this.id=res.id;
        this.loading=false;
      }).catch(res=>{
        console.log(res);
        this.loading=false;
      })
    },
    editPartnerAct(record){
      this.id=record.id;
      this.editPartnerLoading=true;
    },
    cancelEditPartner(){
      this.editPartnerLoading=false;
    },
    okEditPartner(){
      this.editPartnerLoading=false;
      this.getLists();
    },
    //展开行
    async tableExpand(expanded, record) {
      this.childLoading = true;
      this.pid=record.adcode;
      this.id=record.id;
      this.dataType = 1;
      this.getLists();
    },
    //返回上级
    toback(){
      this.childLoading = true;
      this.dataType = 2;
      this.getLists();
    }
  }
}
</script>

<style scoped>
.layout-classes-header {
  background: #FFFFFF;
}

.layout-classes-header {
  padding: 0 20px;
  height: 58px;
  line-height: 57px;
  border-bottom: 1px solid #F0F3F5;
  width: 100%;
}

.layout-classes-content {
  padding: 0px 0px 10px 0px;
  background: #FFFFFF;
  width: 100%;
  min-height: 700px;
}

.classes-menu {
  border-bottom: none;
  line-height: 55px;
  font-size: 14px;
}
</style>
